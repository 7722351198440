import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';
import TextDiplay4 from '../../components/TextDisplay4';

export default function FormulaireEtInscription() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Nous contacter"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <TextDiplay4 />
      </Layout>
    </AnimationRevealPage>
  );
}
